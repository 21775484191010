import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { visuallyHidden } from '../styles/utilities';
import { Svg } from './ui';
import logo from '../images/logo.svg';

const StyledLogo = styled(Link)`
  display: block;
  margin: 20px auto;
  text-align: center;

  ${({ isHomepage }) => {
    if (isHomepage) {
      return css`
        width: 280px;
      `;
    } else {
      return css`
        width: 200px;
      `;
    }
  }}
`;

const StyledLogoSvg = styled(Svg)`
  ${({ isHomepage }) => {
    if (isHomepage) {
      return css`
        height: 90px;
        width: 280px;
      `;
    } else {
      return css`
        height: 65px;
        width: 200px;
      `;
    }
  }}
`;

const StyledLogoText = styled.span`
  ${visuallyHidden()};
`;

const Logo = ({ isHomepage }) => (
  <StyledLogo to="/search" isHomepage={isHomepage}>
    <StyledLogoSvg image={logo} />
    <StyledLogoText>Impact Score</StyledLogoText>
  </StyledLogo>
);

export default Logo;
